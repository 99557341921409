import React, { useState } from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

import { axiosPrivate, axiosPublic } from "../../api/axios";

import "react-circular-progressbar/dist/styles.css";
import "./pdf.css";
import heroBanner1 from "../../assets/pdf/hero-banner-1.webp";
import heroBanner2 from "../../assets/pdf/hero-banner-2.webp";
import tbLogo from "../../assets/pdf/tblogo1.webp";
import skillProfiling1 from "../../assets/pdf/skill-profiling-1.webp";
import skillProfiling2 from "../../assets/pdf/skill-profiling-2.webp";
import skillProfiling3 from "../../assets/pdf/skill-profiling-3.webp";
import infoImg from "../../assets/pdf/info-img.webp";
import assessmentImg from "../../assets/pdf/Assessment-img.webp";
import assessment2 from "../../assets/pdf/Assessment-2.webp";
import score1 from "../../assets/pdf/image1.jpg";
import score2 from "../../assets/pdf/image2.jpg";
import score3 from "../../assets/pdf/image3.png";
import score4 from "../../assets/pdf/image4.jpg";
import score5 from "../../assets/pdf/image5.jpg";
import tableImg from "../../assets/pdf/table-img.webp";
import footerBgx from "../../assets/pdf/footer-bg.webp";
import RadialSeparators from "../../assets/pdf/RadialSeprator";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

function ReportPdf({ id }) {
  const [results, setResults] = useState({});
  const [userName, setuserName] = useState("");
  const [scors, setScors] = useState({});
  const [assessment, setAssessment] = useState({});
  React.useEffect(() => {
    (async () => {
      try {
        const res = await axiosPublic.get(`/details-report/${id}`);
        const resultData = res.data;
        setResults(resultData);
        setScors(resultData.scors);
        setAssessment(resultData.assessment);
        setuserName(resultData.name);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    })();
  }, [id]);

  let labels = [];
  let dataSetStudentScor = [];
  let dataSetIndustryScor = [];
  let dataSetBellowScor = [];

  Object.entries(scors).forEach(([competencyKey, competencyValue]) => {
    const value = competencyValue;
    labels.push(competencyKey);

    // Ensure numeric values for the dataset
    const studentScore = parseFloat(value.total_percentage.toFixed(2));
    dataSetStudentScor.push(studentScore);

    // Calculate industry score with conditions
    let industryScore = 100;
    // if (industryScore > 81) {
    //   industryScore = studentScore;
    // }
    // if (industryScore < 70) {
    //   industryScore = 75;
    // }
    dataSetIndustryScor.push(industryScore);

    // Baseline score
    dataSetBellowScor.push(20);
  });

  const radarOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow the chart to resize dynamically
    layout: {
      padding: {
        top: 50, // Add extra padding at the top
        bottom: 50, // Add extra padding at the bottom
        left: 50, // Add extra padding to the left
        right: 50, // Add extra padding to the right
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Performance Score Analysis", // Chart title
        font: {
          size: 26, // Title font size
          weight: "bold",
        },
        padding: {
          top: 20,
          bottom: 20,
        },
      },
      legend: {
        position: "bottom", // Place the legend at the bottom
        labels: {
          font: {
            size: 12,
          },
          usePointStyle: true, // Use point style for legend
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}%`; // Show percentage values
          },
        },
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        max: 100, // Set maximum value to 100
        ticks: {
          stepSize: 10, // Steps of 10 between ticks
          display: true,
          color: "#000", // Black color for ticks
          backdropColor: "transparent", // No background for tick labels
          callback: (value) => `${value}`, // Append "%" to tick values
        },
        grid: {
          circular: true, // Ensure the grid is circular
          color: "#D6D6D6", // Light gray color for grid lines
        },
        angleLines: {
          color: "#D6D6D6", // Light gray color for angle lines
        },
        pointLabels: {
          display: true,
          font: {
            size: 14, // Reduce font size to avoid label overlap
            weight: "bold",
            maxWidth:'150px'
          },
          color: "#000", // Black color for labels
          padding: 20, // Increase padding to avoid labels getting cut
         
        },
        
        
      },
    },
  }; 

  // Radar chart configuration
  const radarData = {
    labels: labels,
    datasets: [
      {
        label: "Top benchmark",
        data: dataSetIndustryScor, // Example data for Top benchmark
        borderColor: "gray", // Line color
        backgroundColor: "transparent", // No background fill
        pointBackgroundColor: "gray",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "gray",
      },
      {
        label: userName,
        data: dataSetStudentScor, // Example data for student scores
        borderColor: "green", // Line color
        backgroundColor: "transparent", // No background fill
        pointBackgroundColor: "green",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "green",
      },
      {
        label: "Baseline average",
        data: dataSetBellowScor, // Example data for Baseline average
        borderColor: "lightblue", // Line color
        backgroundColor: "transparent", // No background fill
        pointBackgroundColor: "lightblue",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "lightblue",
      },
    ],
  };

  const customPaddingPlugin = {
    id: "custom-padding",
    beforeDraw(chart) {
      const ctx = chart.ctx;
      const canvas = chart.canvas;
      const fontSize = 14; // Adjust font size
      ctx.save();
      ctx.font = `${fontSize}px Arial`;
      ctx.restore();
    },
  };

  return (
    <div style={{ pageBreakInside: "avoid", margin: 0, padding: 0 }}>
      <div className="hero-section pr" style={{ pageBreakAfter: "avoid" }}>
        <picture>
          <source media="(min-width:650px)" srcSet={score1} />
          <source media="(min-width:465px)" srcSet={score1} />
          <img
            className="w-100 h-screen object-cover"
            src={score1}
            alt="Hero-banner"
          />
        </picture>
      </div>
      <div className="hero-section pr" style={{ pageBreakAfter: "avoid" }}>
        <picture>
          <source media="(min-width:650px)" srcSet={score2} />
          <source media="(min-width:465px)" srcSet={score2} />
          <img
            className="w-100 h-screen object-cover"
            src={score2}
            alt="Hero-banner"
          />
        </picture>
      </div>
      <div className="hero-section pr" style={{ pageBreakAfter: "avoid" }}>
        <picture>
          <source media="(min-width:650px)" srcSet={score3} />
          <source media="(min-width:465px)" srcSet={score3} />
          <img
            className="w-100 h-screen object-cover"
            src={score3}
            alt="Hero-banner"
          />
        </picture>
      </div>
      <div className="hero-section pr" style={{ pageBreakAfter: "avoid" }}>
        <picture>
          <source media="(min-width:650px)" srcSet={score4} />
          <source media="(min-width:465px)" srcSet={score4} />
          <img
            className="w-100 h-screen object-cover"
            src={score4}
            alt="Hero-banner"
          />
        </picture>
      </div>
      <div className="hero-section pr" style={{ pageBreakAfter: "avoid" }}>
        <picture>
          <source media="(min-width:650px)" srcSet={score5} />
          <source media="(min-width:465px)" srcSet={score5} />
          <img
            className="w-100 h-screen object-cover"
            src={score5}
            alt="Hero-banner"
          />
        </picture>
      </div>
      {/* <div className="content-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
  <div className="container">
    <h1 className="section-heading text-black">Contents</h1>
  </div>
  <div className="content-item-wrapper pr">
    <div className="content-item container">
      <span>1.</span>
      <span>Skill Profiling Assessment</span>
    </div>
  </div>
  <div className="content-item-wrapper pr">
    <div className="content-item container">
      <span>2.</span>
      <span>Assessment Methodology Overview</span>
    </div>
  </div>
  <div className="content-item-wrapper pr">
    <div className="content-item container">
      <span>3.</span>
      <span>Your Competency Score</span>
    </div>
  </div>
  <div className="content-item-wrapper pr">
    <div className="content-item container">
      <span>4.</span>
      <span>Competency In a nutshell</span>
    </div>
  </div>
  <div className="content-item-wrapper pr">
    <div className="content-item container">
      <span>5.</span>
      <span>Contact</span>
    </div>
  </div>
</div>
<div className="skill-profiling-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
  <div className="container">
    <h1 className="section-heading text-center">
      <span>Skill Profiling</span> Assessment
    </h1>
    <p className="section-desc">
      <p className="w-full text-center !text-3xl !font-bold">Welcome to Your Role-Based Assessment Report</p>
      Congratulations on completing this role-specific assessment! We are excited to share this detailed report with you, which highlights your performance across critical skills and competencies essential for your role. This report aims to give you a clear view of your strengths, areas where you're excelling, as well as specific opportunities for further growth and skill development.
    </p>
    <p className="fw-700 section-desc">In the following pages, you'll find:</p>
    <div className="skill-profiling-item">
      <div className="skill-profiling-item-img">
        <img className="w-100" src={skillProfiling1} alt="" />
      </div>
      <div className="skill-profiling-item-info">
        <h3 className="skill-profiling-item-title">Key Strengths</h3>
        <p className="skill-profiling-item-desc">Areas where you're demonstrating proficiency and making an impact in your role.</p>
      </div>
    </div>
    <div className="skill-profiling-item">
      <div className="skill-profiling-item-img">
        <img className="w-100 object-contain" src={skillProfiling2} alt="" />
      </div>
      <div className="skill-profiling-item-info">
        <h3 className="skill-profiling-item-title">Development Opportunities</h3>
        <p className="skill-profiling-item-desc">Insights into capabilities where focused improvement can enhance your performance and effectiveness.</p>
      </div>
    </div>
    <div className="skill-profiling-item">
      <div className="skill-profiling-item-img">
        <img className="w-100" src={skillProfiling3} alt="" />
      </div>
      <div className="skill-profiling-item-info">
        <h3 className="skill-profiling-item-title">Learning Recommendation</h3>
        <p className="skill-profiling-item-desc">Tailored guidance and actionable steps to help you build on your existing skills and make targeted improvements that align with the demands of your role.</p>
      </div>
    </div>
  </div>
</div>
<div className="info-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
  <div className="container">
    <p className="section-desc">
      As you explore this report, consider how these insights align with your career aspirations. Take time to reflect on how you can apply the recommendations in your day-to-day responsibilities, leveraging your strengths while addressing any challenges. By engaging actively with this feedback, you're setting a strong foundation for growth, adaptability, and long-term success in your role.
    </p>
    <p className="section-desc">
      We are here to support you in reaching your full potential. Embrace this journey as an opportunity to deepen your expertise, unlock new skills, and achieve greater success. Happy learning and development!
    </p>
    <div className="info-section-img-zx">
      <img className="w-full h-full" src={infoImg} alt="" />
    </div>
  </div>
</div>
<div className="Assessment-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
  <div className="container">
    <h1 className="section-heading">
      <span>Assessment</span> Methodology Overview
    </h1>
    <p className="section-desc">
      This assessment is designed to evaluate learners across core competencies and specific capabilities required for their roles.
      <br />
      Our approach combines -
    </p>
    <ul className="list-disc list-inside">
      <li>Scenario-based questions,</li>
      <li>Practical problem-solving tasks, and</li>
      <li>Objective performance metrics to measure real-world application of skills.</li>
    </ul>
    <p className="section-desc">
      By simulating role-specific challenges, the assessment captures each learner's ability to understand, analyze, and respond effectively to situations they may encounter in their job.
    </p>
    <div className="info-section-img-zxx">
      <img className="w-100" src={assessmentImg} alt="" />
    </div>
    <p className="section-desc">
      Each competency is further broken down into key capabilities, allowing for a precise analysis of strengths and improvement areas. The scoring framework is designed to reflect proficiency levels, with thresholds set to identify high performers, emerging talent, and areas where additional development may be beneficial.
    </p>
    <p className="section-desc">
      This structured and holistic approach ensures a balanced evaluation, offering clear, actionable insights that guide personal development and foster role-specific growth.
    </p>
  </div>
</div>
<div className="Assessment-section section-padding section section-bg" style={{ pageBreakInside: 'avoid', marginTop: 0 }}>
  <div className="container">
    <h1 className="section-heading">
      <span>Assessment</span> Scoring Methodology
    </h1>
    <p className="section-desc !font-bold !text-3xl">Scores are segmented by thresholds</p>
    <ul className="list-disc list-inside">
      <li>Below 50% indicates foundational areas that may need targeted support.</li>
      <li>51%-75% suggests moderate proficiency where improvement efforts can be focused.</li>
      <li>Above 75% highlights strong capability and potential for advanced application.</li>
    </ul>
    <p className="section-desc">
      This detailed breakdown not only supports the learner's self-awareness but also aligns development initiatives with specific competencies, enhancing targeted learning strategies for continued growth.
    </p>
    <div className="!mt-10">
      <img className="w-100" src={assessment2} alt="" />
    </div>
  </div>
</div> */}

      <div
        className="score-section section-padding section section-bg custom-bg-new container-size"
        style={{ pageBreakInside: "avoid", marginTop: 0 }}
      >
        <div className="container">
          <h1 className="section-heading flex flex-col justify-start items-start">
            <span className="font-NeueMachina blue-text ">Your Competency</span>{" "}
            {/* <br /> */}
            <span className="font-NeueMachina ">Score</span>
          </h1>
          {/* <br /> */}
          <div className="score-section-wrapper">
            <div className="block-title-wrapper d-flex align-items-center">
              <h4 className="block-title Competency w-60 text-bold">Competency</h4>
              <h4 className="block-title Score w-40 text-center text-bold">Score</h4>
            </div>
            {Object.entries(scors)
              .sort(([, a], [, b]) => b.total_percentage - a.total_percentage)
              .map(([competencyKey, competencyValue], index) => (
                <div className="score-block-wrapper d-flex align-items-center">
                  <div className="competency-block-item d-flex align-items-center w-60">
                    <img
                      src="https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/score-1.webp"
                      alt={competencyKey}
                    />
                    <h3 className="competency-block-item-title section-desc fw-700">
                      {competencyKey}
                    </h3>
                  </div>
                  <div className="score-progress-wrapper w-40 text-center">
                    <div style={{ width: 100, height: 100 }}>
                      <CircularProgressbarWithChildren
                        value={Math.round(competencyValue.total_percentage)}
                        text={`${Math.round(
                          competencyValue.total_percentage
                        )}%`}
                        strokeWidth={10}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                        })}
                      >
                        <RadialSeparators
                          count={12}
                          style={{
                            background: "#fff",
                            width: "2px",
                            height: `${10}%`,
                          }}
                        />
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className="Assessment-section section-padding section section-bg container-size"
        style={{}}
      >
        <div className="container">
          <h1 className="section-heading flex flex-col justify-start items-start">
            <span className="font-NeueMachina blue-text">Competency</span>
            <span className="font-NeueMachina">In a nutshell</span>
          </h1>
          
          <div
            className="chart-container"
            style={{ width: "900px", height: "800px", margin: "auto" }}
          >
           
            <Radar
              data={radarData}
              options={radarOptions}
              plugins={[customPaddingPlugin]}
            />
           
          </div>
        </div>
      </div>


      {/*  */}
      <div
        className="Assessment-section section-padding section section-bg  container-size"
        style={{}}
      >
        <div className="container">
          <h1 className="section-heading flex flex-col justify-start items-start">
            <span className="font-NeueMachina blue-text">Capability</span>
            <span className="font-NeueMachina">In a nutshell</span>
          </h1>

          <p className="section-desc !font-bold !text-3xl">
            Scores are segmented by thresholds
          </p>
          <ul className="list-disc list-inside">
            <li>
              <b>Below 50%</b> indicates foundational areas that may need targeted
              support.
            </li>
            <li>
              <b>51%-75%</b> suggests moderate proficiency where improvement efforts
              can be focused.
            </li>
            <li>
              <b>Above 75%</b> highlights strong capability and potential for
              advanced application.
            </li>
          </ul>
          <p className="section-desc">
            This detailed breakdown not only supports the learner's self-awareness
            but also aligns development initiatives with specific competencies,
            enhancing targeted learning strategies for continued growth.
          </p>
          <div className="max-w-full mx-auto mt-4">
            {Object.entries(scors).map(([competencyKey, competencyValue]) => (
              <div key={competencyKey} className="flex flex-col gap-3 bg-gray-100 mb-2 p-2 rounded-md">
                <h3 className="text-lg font-semibold mt-3">{competencyKey}</h3>

                {competencyValue.capabilities.map((cap, index) => (
                  <div
                    key={index}
                    className=" "
                    style={{ pageBreakInside: "avoid" }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="mb-1 text-base font-medium ">{cap.title}</div>
                    </div>
                    <div className="w-full bg-gray-300  h-6 dark:bg-gray-700">
                      <div
                        className="bg-[#118d00] h-6 text-center"
                        style={{
                          width: `${parseFloat(cap.percentage).toFixed(1)}%`,
                        }} // Format width value
                      >
                        <span className="mb-1 text-base font-bold  text-white text-center">
                          {parseFloat(cap.percentage).toFixed(1)}%
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>



      {/* <div
        className="Assessment-section section-padding section section-bg  container-size"
        style={{}}
      >
        <div className="container">
          <h1 className="section-heading flex flex-col justify-start items-start">
            <span className="font-NeueMachina blue-text">Competency</span>
            <span className="font-NeueMachina">In a nutshell</span>
          </h1>
          <p className="section-desc !font-bold !text-3xl">
            Scores are segmented by thresholds
          </p>
          <ul className="list-disc list-inside">
            <li>
              <b>Below 50%</b> indicates foundational areas that may need
              targeted support.
            </li>
            <li>
              <b>51%-75%</b> suggests moderate proficiency where improvement
              efforts can be focused.
            </li>
            <li>
              <b>Above 75%</b> highlights strong capability and potential for
              advanced application.
            </li>
          </ul>
          <p className="section-desc">
            This detailed breakdown not only supports the learner's
            selfawareness but also aligns development initiatives with specific
            competencies, enhancing targeted learning strategies for continued
            growth.
          </p>
          <div className="max-w-full mx-auto mt-4">
            {Object.entries(scors).map(([competencyKey, competencyValue]) => {
              return (
                <div key={competencyKey} className="flex flex-col gap-3 bg-gray-100 mb-2 p-2 rounded-md">
                  <h3 className="text-lg font-semibold mt-3 ">
                    {competencyKey}
                  </h3>

                  {competencyValue.capabilities.map((cap, index) => (
                    <div
                      className=" "
                      key={`${competencyKey}-${index}`}
                      style={{ pageBreakInside: "avoid" }}
                    >
                      <div className="flex items-center justify-between">
                        <div className="mb-1 text-base font-medium ">
                          {cap.title}
                        </div>
                       
                      </div>
                      <div className="w-full bg-gray-300  h-6  dark:bg-gray-700">
                        <div
                          className="bg-[#118d00] h-6 text-center"
                          style={{
                            width: `${parseFloat(cap.percentage).toFixed(1)}%`,
                          }}
                        >
                           <span className="text-white  text-base font-medium ">
                          {parseFloat(cap.percentage).toFixed(1)}%{" "}
                        </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div> */}

      <div
        className="score-section section-padding section section-bg container-size"
        style={{}}
      >
        <div className="container">
          <h1 className="section-heading flex flex-col justify-start items-start">
            <span className="font-NeueMachina blue-text">Your</span>
            <span className="font-NeueMachina">Strengths</span>
          </h1>

          {/* <br /> */}
          <div className="score-section-wrapper">
            <div className="block-title-wrapper d-flex align-items-center">
              <h4 className="block-title Competency w-60">Competency</h4>
              <h4 className="block-title Score w-40 text-center">Score</h4>
            </div>
            {Object.entries(scors)
              .filter(
                ([, competencyValue]) => competencyValue.total_percentage > 75
              ) // Filter by total_percentage > 50
              .sort(([, a], [, b]) => b.total_percentage - a.total_percentage)
              .map(([competencyKey, competencyValue], index) => (
                <div
                  className="score-block-wrapper d-flex align-items-center"
                  key={index}
                >
                  <div className="competency-block-item d-flex align-items-center w-60">
                    <img
                      src="https://third-bracket.s3.eu-north-1.amazonaws.com/pdf/score-1.webp"
                      alt={competencyKey}
                    />
                    <h3 className="competency-block-item-title section-desc fw-700">
                      {competencyKey}
                    </h3>
                  </div>
                  <div className="score-progress-wrapper w-40 text-center">
                    <div style={{ width: 100, height: 100 }}>
                      <CircularProgressbarWithChildren
                        value={Math.round(competencyValue.total_percentage)}
                        text={`${Math.round(
                          competencyValue.total_percentage
                        )}%`}
                        strokeWidth={10}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                        })}
                      >
                        <RadialSeparators
                          count={12}
                          style={{
                            background: "#fff",
                            width: "2px",
                            height: `${10}%`,
                          }}
                        />
                      </CircularProgressbarWithChildren>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        className="Assessment-section section-padding section section-bg container-size"
        style={{}}
      >
        <div className="container">
          <h1 className="section-heading flex flex-col justify-start items-start ">
            <span className="font-NeueMachina blue-text">Your</span>
            <span className="font-NeueMachina"> Areas of Improvement</span>
          </h1>

          {/* <br /> */}
          {Object.entries(scors).map(([competencyKey, competencyValue]) => (
            <div style={{}} key={competencyKey}>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr style={{ border: "1px solid #ddd" }}>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize: 16,
                        maxWidth: "30%",
                        width: 300,
                      }}
                      className="m-bold"
                    >
                      {competencyKey}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize: 16,
                      }}
                      className="m-bold"
                    >
                      Your Score
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize: 16,
                      }}
                      className="m-bold"
                    >
                      Areas of Improvement/ Potential for Advance application
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competencyValue.capabilities.map((cap, index) => {
                    let titleContent = "";
                    if (cap.percentage < 51) {
                      titleContent = "Score < 50%: Needs Improvement";
                    } else if (cap.percentage >= 51 && cap.percentage <= 75) {
                      titleContent =
                        "Score 51% - 75%: Meets Basic Expectations";
                    } else {
                      titleContent = "Score > 75%: Exceeds Expectations";
                    }
                    return (
                      <tr key={index} style={{ border: "1px solid #ddd" }}>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize: 16,
                            maxWidth: "30%",
                            width: 300,
                          }}
                          className="m-bold"
                        >
                          {cap.title} - ({titleContent})
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize: 16,
                          }}
                          className="m-bold"
                        >
                          {parseFloat(cap.percentage).toFixed(1)}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                          }}
                        >
                          {/* Render HTML safely */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: cap.areasOfImprovement || "",
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>

      <div
        className="Assessment-section section-padding section section-bg container-size "
        style={{}}
      >
        <div className="container">
          <h1 className="section-heading flex !text-left flex-col justify-start items-start">
            <span className="font-NeueMachina blue-text">Our</span>
            <span className="font-NeueMachina text-nowrap">Learning Recommendation</span>
          </h1>
          {/* <br /> */}
          {Object.entries(scors).map(([competencyKey, competencyValue]) => (
            <div style={{}} key={competencyKey}>
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr style={{ border: "1px solid #ddd" }}>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize: 16,
                        maxWidth: "30%",
                        width: 300,
                      }}
                      className="m-bold"
                    >
                      {competencyKey}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize: 16,
                      }}
                      className="m-bold"
                    >
                      Your Score
                    </th>
                    <th
                      style={{
                        backgroundColor: "#2f5335",
                        padding: "12px",
                        textAlign: "left",
                        border: "1px solid #ddd",
                        color: "white",
                        fontSize: 16,
                      }}
                      className="m-bold"
                    >
                      Learning Recommendation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {competencyValue.capabilities.map((cap, index) => {
                    let titleContent = "";
                    if (cap.percentage < 51) {
                      titleContent = "Score < 50%: Needs Improvement";
                    } else if (cap.percentage >= 51 && cap.percentage <= 75) {
                      titleContent =
                        "Score 51% - 75%: Meets Basic Expectations";
                    } else {
                      titleContent = "Score > 75%: Exceeds Expectations";
                    }
                    return (
                      <tr key={index} style={{ border: "1px solid #ddd" }}>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize: 16,
                            maxWidth: "30%",
                            width: 300,
                          }}
                          className="m-bold"
                        >
                          {cap.title} - ({titleContent})
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                            fontSize: 16,
                          }}
                          className="m-bold"
                        >
                          {parseFloat(cap.percentage).toFixed(1)}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "left",
                            border: "1px solid #ddd",
                          }}
                        >
                          {/* Render HTML safely */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: cap.recommendations || "",
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
      <footer className="pr">
        <img className="w-100 object-cover h-screen" src={footerBgx} alt="" />
        <span className="footer-logo">
          <img className="w-100" src={tbLogo} alt="" />
        </span>
        <div className="footer-content-wrapper container">
          <h1 className="footer-title text-center m-0">
            Empower Your Workforce, Elevate Success
          </h1>
          <p className="section-desc text-center">
            Unleashing Potential Through Comprehensive Skills Profiling for
            Achieving Successful Employees.
          </p>
          <p className="contact-detail text-center">
            Chhandan Chakraborty | +91 9147064666
            <br />
            chandan@learningbooth.co
          </p>
        </div>
      </footer>
    </div >
  );
}

export default ReportPdf;
