import MainContent from '../../components/Certificate/MainContent';

const Certificate = () => {
    const resultId = new URLSearchParams(window.location.search).get('resultId');
    return (
        <MainContent id={resultId || ''} />
    );
};

export default Certificate;
