import React, { useState } from 'react';
import logo from '../../assets/tblogo1.png';

import { Link } from 'react-router-dom';

const Header2 = () => {
    const [menu, setMenu] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    return (
        <header className="bg-[#061927] top-0 sticky z-50">
            <nav
                className="mx-auto flex py-4 max-w-7xl items-center justify-between lg:py-5 lg:px-0 px-4"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <img className="lg:h-12 h-8 w-auto" src={logo} alt="" />
                    </a>
                </div>
                <div className='flex-1 flex justify-end items-center gap-24'>
                    <div className='hidden lg:flex flex-row items-center justify-end gap-[69px] '>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap ff-mulish "
                        >
                            Start hiring
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap ff-mulish "
                        >
                            Get hired
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap ff-mulish "
                        >
                            Products
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap ff-mulish "
                        >
                           Solutions
                        </Link>

                    </div>
                    <div className='flex items-center justify-end gap-6'>
                        <Link
                            to="/login"
                            className="h-[37px] flex items-center justify-center lg:text-base/5 text-sm font-bold leading-7 text-white text-nowrap ff-mulish lg:border-0 border borde-white lg:px-0 px-4 rounded-sm"
                        >
                            Log In
                        </Link>
                        <Link
                            to={'/sign-up'}
                            className="focus:outline-none text-white bg-[#407BFF] hover:bg-white hover:text-[#407BFF]  font-medium rounded-sm text-lg  px-5 h-[37px] hidden  lg:flex items-center justify-center text-nowrap ff-mulish"
                        >
                            Sign Up
                        </Link>
                    </div>
                </div>


            </nav>
        </header>
    );
};

export default Header2;
