
import top1 from '../../assets/new-image/hero-right.png';
import top2 from '../../assets/new-image/hero-left.png';
import bannerImage from '../../assets/new-home/bner.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const HomeBanner = () => {

    return (
        <section className="bg-banner-home relative " >
            <div className='max-w-7xl lg:px-0 px-4 mx-auto'>
                <div className="flex lg:flex-row flex-col items-center justify-between gap-8 lg:py-20 pt-7 ">
                    <div className='flex-1'>
                        <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#061927] lg:max-w-full'>
                            Transforming Talent, Empowering Growth
                        </h3>
                        <p className='mt-0.5  font-normal lg:text-lg text-base tracking-tight text-[#40566D] lg:max-w-[92%] ff-inter'>At Third Bracket, we’re reshaping the future of talent acquisition and management. Our AI-powered skills profiling platform, paired with expert talent advisory services, helps you:</p>
                        <div className='flex flex-row items-center justify-start gap-6 mt-[28px]'>
                            <button className='bg-[#FFC727]  px-8 py-3.5 flex items-center justify-center gap-2.5  border-[#FFC727]'>
                                <span className='text-[#455A64] text-base/normal font-bold ff-inter '>Sign Up</span>
                                <span>
                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 12.5H19M19 12.5L12 5.5M19 12.5L12 19.5" stroke="#455A64" />
                                    </svg>
                                </span>
                            </button>
                            <button className='border px-8 bg-white border-[#455A64] py-3.5 flex items-center justify-center text-base/normal font-bold ff-pop  text-[#455A64]'>
                                Know More
                            </button>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <img src={bannerImage} alt="bannerImage" width={662} height={479} className='max-w-full object-fill' />
                    </div>
                </div>
            </div>
            <div className='top-image-1 lg:block hidden absolute lg:-top-[20%] xl:-top-[10%] left-0 z-[-1]'>
                <img src={top2} className='max-w-full aspect-square' width={720} height={720} alt="" />
            </div>
            <div className='top-image-1 lg:block hidden absolute lg:-top-[21%] xl:-top-[12%] right-0 z-[-1]'>
                <img src={top1} width={720} height={300} className='max-w-full' alt="" />
            </div>
        </section>
    )
}

export default HomeBanner;