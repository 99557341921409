import React, { useState } from 'react';
import logo from '../../assets/new-image/logo2.png';

import { Link } from 'react-router-dom';

const Header2 = () => {
    const [menu, setMenu] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    return (
        <header className="bg-[#061927] top-0 sticky z-50">
            <nav
                className="mx-auto flex py-4 max-w-7xl items-center justify-between lg:py-5 lg:px-0 px-4"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <a href="/" className="w-[120px]">
                        <img className="w-full h-auto" src={logo} alt="" />
                    </a>
                </div>
                <div className='flex-1 flex justify-end items-center gap-24'>
                    <div className='hidden lg:flex flex-row items-center justify-end gap-[69px] '>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Our Story
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Products
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Solutions
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Commercials
                        </Link>

                    </div>
                    <div className='flex items-center justify-end gap-6'>
                        <Link
                            to={'/sign-up'}
                            className="focus:outline-none  bg-[#FFC727] hover:bg-white font-medium rounded-sm text-base lg:text-lg  px-5 py-2   flex items-center justify-center gap-1 font-mulish"
                        >
                            <span className='text-[#061927] flex font-mulish'>
                                Login
                            </span>
                            <span className='flex items-center justify-center'>
                                <svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="#061927" />
                                </svg>
                            </span>
                        </Link>
                    </div>
                </div>


            </nav>
        </header>
    );
};

export default Header2;
