import React from 'react';
import logo from '../../assets/new-image/logo.png';

const Footer2 = () => {
    return (
        <footer className="bg-dark-footer">
            <div className="relative isolate px-6 mx-auto max-w-7xl lg:px-8 md:pt-24 md:pb-20 sm:pt-20 sm:pb-16 pt-12 pb-8 md:flex">
                <div className="flex flex-row items-center justify-between w-full">
                    {/* <div className="flex lg:flex-1 mt-7">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img className="lg:h-20 h-8 w-auto" src={logo} alt="" />
                        </a>
                        <sup className='text-white ff-pop text-sm'>TM</sup>
                    </div> */}
                    <div>
                        <h4 className="text-white md:text-3xl lg:text-[46px] text-[28px] font-semibold lg:mb-1.5 sm:mb-2.5 mb-3.5 ff-pop">
                            Get in Touch
                        </h4>
                        <ul className='pl-0 m-0'>
                            <li className="mb-0.5">
                                <a
                                    href="mailto:sales@learningbooth.co"
                                    className="text-[#D1D1D1] lg:text-2xl sm:text-xl text-base ff-pop"
                                >
                                    sales@learningbooth.co
                                </a>
                            </li>
                            <li>
                                <a
                                    href="callto:+919147064666"
                                    className="text-[#D1D1D1] lg:text-2xl sm:text-xl text-base ff-pop"
                                >
                                   +91 9147064666 
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer2;
