import React, { useState } from 'react';
// import google from '../../assets/google-logo.svg';
// import grayStart from '../../assets/gray-star.svg';
// import clock from '../../assets/clock.svg';
import JobModal from './JobModal';
import { axiosPrivate } from '../../api/axios';

const MainContent = () => {
  const [job, setJob] = useState<any>(null);
  const viewDetails = (j: any) => {
    var jobDetails: any = document.getElementById('jodDetails');
    var jobDetailsOverlay: any = document.getElementById('jodDetailsOverlay');
    jobDetails.classList.toggle('active');
    jobDetailsOverlay.classList.toggle('active');
    setJob(j);
  };

  const [allJobs, setAllJobs] = useState([]);

  React.useEffect(() => {
    (async () => {
      await axiosPrivate('/job/frontend').then((resp) => setAllJobs(resp.data));
    })();
  }, []);

  return (
    <>
      <section className="dasboard-content lg:w-[calc(100%-15rem)] w-full bg-light-sm min-h-[calc(100vh-104px)] sm:px-6 px-3 sm:py-7 py-4">
        <h3 className="md:text-2xl text-xl text-gray-black font-medium mb-4">
          Jobs
        </h3>
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-6">
          <ul className="flex flex-wrap -mb-px">
            <li className="md:mr-20 sm:mr-8 mr-6">
              <div
                className="inline-block pb-4 border-b-4 font-semibold text-gray-black sm:text-base text-sm border-amber-300 active"
                aria-current="page"
              >
                Opening <span className="font-normal">({allJobs?.length})</span>
              </div>
            </li>
            <li className="md:mr-20 sm:mr-8 mr-6">
              <div className="inline-block pb-4 border-b-4 border-transparent sm:text-base text-sm">
                Recommended <span className="font-normal">(0)</span>
              </div>
            </li>
            <li className="md:mr-20 sm:mr-8 mr-6">
              <div className="inline-block pb-4 border-b-4 border-transparent sm:text-base text-sm">
                Applied <span className="font-normal">(0)</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="md:w-12/12 w-full px-3">
            {allJobs?.map((job: any, idx) => {
              return (
                <div onClick={() => viewDetails(job)} key={idx}>
                  <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
                    <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                      {job.title}
                    </h3>
                    <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                      {/* <img
                        width={24}
                        height={24}
                        className="mr-2"
                        src={google}
                        alt=""
                      /> */}
                      {job.companyName}
                      {/* <span className="flex items-center ml-4 text-black">
                        {job.rating}{" "}
                        <img className="ml-1" src={grayStart} alt="" />
                      </span> */}
                    </h4>
                    <div className="flex mb-2">
                      <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                        <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                        {job.jobType}
                      </div>
                      <div className="flex items-center">
                        {/* <img
                          className="mr-2"
                          width={24}
                          height={24}
                          src={clock}
                          alt=""
                        /> */}
                        {/* <p className="text-dark-black sm:text-base text-sm">
                          {job.hiringStatus}
                        </p> */}
                      </div>
                    </div>
                    {/* <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
                      You’re fit for this job
                    </span> */}
                    <p className="text-dark-black lg:text-base text-sm">
                      {job.jobDetails?.slice(0, 200)}...
                    </p>
                  </div>
                </div>
              );
            })}
            {/* <div onClick={viewDetails}>
              <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
                <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                  UX UI Designer
                </h3>
                <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                  <img
                    width={24}
                    height={24}
                    className="mr-2"
                    src={google}
                    alt=""
                  />
                  Company Name
                  <span className="flex items-center ml-4 text-black">
                    2.5 <img className="ml-1" src={grayStart} alt="" />
                  </span>
                </h4>
                <div className="flex mb-2">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                    Remote
                  </div>
                  <div className="flex items-center">
                    <img
                      className="mr-2"
                      width={24}
                      height={24}
                      src={clock}
                      alt=""
                    />
                    <p className="text-dark-black sm:text-base text-sm">
                      Urgently hiring
                    </p>
                  </div>
                </div>
                <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
                  You’re fit for this job
                </span>
                <p className="text-dark-black lg:text-base text-sm">
                  Come join our exciting team at AxleHire as a Sr UX/UI
                  Designer, or User Experience/User Interface Designer as we
                  address last mile opportunities. The primary responsibility is
                  for creating and optimizing the user experience across all
                  AxleHire tools and mobile app.
                </p>
              </div>
            </div>
            <div onClick={viewDetails}>
              <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
                <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                  UX UI Designer
                </h3>
                <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                  <img
                    width={24}
                    height={24}
                    className="mr-2"
                    src={google}
                    alt=""
                  />
                  Company Name
                  <span className="flex items-center ml-4 text-black">
                    2.5 <img className="ml-1" src={grayStart} alt="" />
                  </span>
                </h4>
                <div className="flex mb-2">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                    Remote
                  </div>
                  <div className="flex items-center">
                    <img
                      className="mr-2"
                      width={24}
                      height={24}
                      src={clock}
                      alt=""
                    />
                    <p className="text-dark-black sm:text-base text-sm">
                      Urgently hiring
                    </p>
                  </div>
                </div>
                <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
                  You’re fit for this job
                </span>
                <p className="text-dark-black lg:text-base text-sm">
                  Come join our exciting team at AxleHire as a Sr UX/UI
                  Designer, or User Experience/User Interface Designer as we
                  address last mile opportunities. The primary responsibility is
                  for creating and optimizing the user experience across all
                  AxleHire tools and mobile app.
                </p>
              </div>
            </div>
            <div onClick={viewDetails}>
              <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
                <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                  UX UI Designer
                </h3>
                <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                  <img
                    width={24}
                    height={24}
                    className="mr-2"
                    src={google}
                    alt=""
                  />
                  Company Name
                  <span className="flex items-center ml-4 text-black">
                    2.5 <img className="ml-1" src={grayStart} alt="" />
                  </span>
                </h4>
                <div className="flex mb-2">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                    Remote
                  </div>
                  <div className="flex items-center">
                    <img
                      className="mr-2"
                      width={24}
                      height={24}
                      src={clock}
                      alt=""
                    />
                    <p className="text-dark-black sm:text-base text-sm">
                      Urgently hiring
                    </p>
                  </div>
                </div>
                <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
                  You’re fit for this job
                </span>
                <p className="text-dark-black lg:text-base text-sm">
                  Come join our exciting team at AxleHire as a Sr UX/UI
                  Designer, or User Experience/User Interface Designer as we
                  address last mile opportunities. The primary responsibility is
                  for creating and optimizing the user experience across all
                  AxleHire tools and mobile app.
                </p>
              </div>
            </div>
            <div onClick={viewDetails}>
              <div className="md:p-10 sm:p-6 p-4 bg-white rounded-2xl md:mb-6 sm:mb-4 mb-3 border-2 border-white hover:border-yellow-300 transition-all">
                <h3 className="lg:text-2xl sm:text-xl text-base mb-2 font-medium">
                  UX UI Designer
                </h3>
                <h4 className="flex items-center text-dark-black lg:text-base text-sm mb-2">
                  <img
                    width={24}
                    height={24}
                    className="mr-2"
                    src={google}
                    alt=""
                  />
                  Company Name
                  <span className="flex items-center ml-4 text-black">
                    2.5 <img className="ml-1" src={grayStart} alt="" />
                  </span>
                </h4>
                <div className="flex mb-2">
                  <div className="flex items-center mr-6 text-dark-black sm:text-base text-sm">
                    <span className="w-2 h-2 rounded bg-dark-black inline-block mr-2" />
                    Remote
                  </div>
                  <div className="flex items-center">
                    <img
                      className="mr-2"
                      width={24}
                      height={24}
                      src={clock}
                      alt=""
                    />
                    <p className="text-dark-black sm:text-base text-sm">
                      Urgently hiring
                    </p>
                  </div>
                </div>
                <span className="py-2 px-3 rounded-lg text-light-green bg-light-green lg:text-base text-sm font-medium mb-2 inline-block">
                  You’re fit for this job
                </span>
                <p className="text-dark-black lg:text-base text-sm">
                  Come join our exciting team at AxleHire as a Sr UX/UI
                  Designer, or User Experience/User Interface Designer as we
                  address last mile opportunities. The primary responsibility is
                  for creating and optimizing the user experience across all
                  AxleHire tools and mobile app.
                </p>
              </div>
            </div> */}
          </div>
          {/* <div className="md:w-4/12 w-full px-3">
            <div className="sm:p-6 p-4 bg-white rounded-2xl flex items-center justify-between sm:mb-4 mb-3">
              <h4 className="font-medium md:text-xl text-base">Job Filter</h4>
              <a href="/" className="md:text-base text-sm text-indigo-500">
                Clear All
              </a>
            </div>
            <div className="sm:p-6 p-4 bg-white rounded-2xl sm:mb-4 mb-3">
              <div className="flex justify-between mb-6">
                <h4 className="font-semibold md:text-base text-sm">
                  Experience Level
                </h4>
                <a href="/" className="md:text-base text-sm text-dark-black">
                  Clear All
                </a>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Intro</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Intermediate</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3 rounded-xl">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className=" md:text-base text-sm mb-1">Expert</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl sm:mb-4 mb-3">
              <div className="flex justify-between mb-6">
                <h4 className="font-semibold md:text-base text-sm">Job Type</h4>
                <a href="/" className="md:text-base text-sm text-dark-black">
                  Clear All
                </a>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Full Time</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Part Time</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3 rounded-xl">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Contract</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl sm:mb-4 mb-3">
              <div className="flex justify-between sm:mb-6 mb-">
                <h4 className="font-semibold md:text-base text-sm">Location</h4>
                <a href="/" className="md:text-base text-sm text-dark-black">
                  Clear All
                </a>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className=" md:text-base text-sm mb-1">On Site</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Remote</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="relative">
                <input
                  type="text"
                  className="sm:text-base text-sm block w-full py-1.5 bg-light-sm border-gray-shade rounded-xl h-12 sm:pl-12 pl-8"
                  placeholder="Search By Location"
                />
                <button
                  type="button"
                  className="absolute left-4 top-2/4 -translate-y-1/2"
                >
                  <svg
                    className="sm:h-5 h-4 sm:w-5 w-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                      stroke="#323554"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.9999 20.9999L16.6499 16.6499"
                      stroke="#323554"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl sm:mb-4 mb-3">
              <div className="flex justify-between mb-6">
                <h4 className="font-semibold md:text-base text-sm">
                  Applications
                </h4>
                <a href="/" className="md:text-base text-sm text-dark-black">
                  Clear All
                </a>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">Less than 10</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center sm:mb-4 mb-3">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">10 to 100</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
                  />
                  <h4 className="md:text-base text-sm mb-1">More than 100</h4>
                </div>
                <div>
                  <p className="md:text-base text-sm text-dark-black">
                    253 jobs
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <JobModal close={viewDetails} job={job} />
    </>
  );
};

export default MainContent;
