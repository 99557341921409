import React, { useState } from 'react'
import check from '../../assets/new-home/Check circle.svg';
import check2 from '../../assets/new-home/Check circle2.svg';

function ServiceAccord({ quesiton, first = false }) {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={'accordian cursor-pointer'} onClick={() => { setIsOpen(prev => !prev) }}>
            <div className='flex flex-row items-center justify-between py-4 border-b border-b-[#DFDFDF]'>
                <h3 className='text-[#191919] font-semibold text-[26px] ff-pop leading-tight flex-1'>{quesiton}</h3>
                <span className={isOpen ? 'rotate-180 transition-all' : 'transition-all'}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.41205 6.91083C4.73748 6.58539 5.26512 6.58539 5.59056 6.91083L10.0013 11.3216L14.412 6.91083C14.7375 6.58539 15.2651 6.58539 15.5906 6.91083C15.916 7.23626 15.916 7.7639 15.5906 8.08934L10.5906 13.0893C10.2651 13.4148 9.73748 13.4148 9.41205 13.0893L4.41205 8.08934C4.08661 7.7639 4.08661 7.23626 4.41205 6.91083Z" fill="#132644" />
                    </svg>
                </span>
            </div>
            <div className={isOpen ? 'block' : 'hidden'}>
                {
                    !first ?

                        <div className="service-2  flex-1 pr-3.5 pt-[71px]  pl-4  pb-20">
                            {/* <h3 className='ff-NM pl-3 text-[26px] leading-tight font-extrabold text-[#061927] mb-[61px]'>+ 1,49,999 <sup>$</sup></h3> */}
                            <ul className='list-none pl-0 m-0 flex flex-col  z-10 relative gap-[18px] w-full'>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-[#061927] ff-mulish text-sm lg:text-lg font-normal flex-1'>500 Skill Profile </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-[#061927] ff-mulish text-sm lg:text-lg font-normal flex-1'>Free Job Posting(unlimited) </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-[#061927] ff-mulish text-sm lg:text-lg font-normal flex-1'>Application Dashboard-(able to use existing dataset for skill profiling)  </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-[#061927] ff-mulish text-sm lg:text-lg font-normal flex-1'>Skill Report for user
                                    </span>
                                </li>

                            </ul>
                        </div>
                        :
                        <div className="service-1  flex-1 pr-3.5 pt-[71px]  pl-4  pb-20">
                            {/* <h3 className='ff-NM pl-3 text-[26px] leading-tight font-extrabold text-white mb-0'>3,25,000 <sup>$</sup></h3> */}
                            <p className='text-[26px] leading-tight font-extrabold text-white mt-0 mb-[30px] pl-3 ff-mulish'>(Launching Price)</p>
                            <ul className='list-none pl-0 m-0 flex flex-col  z-10 relative gap-[18px] w-full'>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check2} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-white ff-mulish text-lg font-normal flex-1'>Application setup </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check2} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-white ff-mulish text-lg font-normal flex-1'>Separate Login panel with company branding </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check2} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-white ff-mulish text-lg font-normal flex-1'>Pre-Loaded Skill Profile assessment for Employee with </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check2} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-white ff-mulish text-lg font-normal flex-1'>Bulk Employee database upload </span>
                                </li>
                                <li className='flex-row flex gap-4 items-start'>
                                    <span>
                                        <img src={check2} alt="" width={32} height={32} className='text-fill' />
                                    </span>
                                    <span className='text-white ff-mulish text-lg font-normal flex-1'>Overall Report analysis </span>
                                </li>
                            </ul>
                        </div>

                }

            </div>
        </div >
    )
}

export default ServiceAccord