import React from "react";
// import Header from "../../components/shared/Header";
import Header from '../../components/landing02/header';
import Header2 from '../../components/shared/Header2'
import SignUpForm from "../../components/Login/LoginForm";
// import Footer from "../../components/shared/Footer";
import Footer from '../../components/landing02/footer';

const Login = () => {
  return (
    <>
      <Header2 />
      <SignUpForm />
      <Footer />
    </>
  );
};
 
export default Login;
