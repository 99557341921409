import React, { useState } from 'react';
import MainLayout from '../../layout/MainLayout';
import StepOneHeader from '../../components/AssessmentsAndTests/StepOne/StepOneHeader';
import StepOneContent from '../../components/AssessmentsAndTests/StepOne/StepOneContent';
import StepTwoContent from '../../components/AssessmentsAndTests/StepTwo/StepTwoContent';
import StepFourHeader from '../../components/AssessmentsAndTests/StepFour/StepFourHeader';
import StepThreeContent from '../../components/AssessmentsAndTests/StepThree/StepThreeContent';
import StepSixHeader from '../../components/AssessmentsAndTests/StepSix/StepSixHeader';
import StepSixContent from '../../components/AssessmentsAndTests/StepSix/StepSixContent';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosPrivate } from '../../api/axios';
import { toast } from 'react-toastify';

const AssessmentsOverview = () => {
  const initialStep: any = new URLSearchParams(window.location.search).get(
    'step'
  );
  const [step, setStep] = useState(parseInt(initialStep) || 0);
  const [progress, setProgress] = useState(0);
  const { id } = useParams();
  const [unansweredQuestionIds, setUnansweredQuestionIds] = useState([]);
  const resultId = new URLSearchParams(window.location.search).get('resultId');
  React.useEffect(() => {
    switch (step) {
      case 0:
        setProgress(20);
        break;
      case 1:
        setProgress(45);
        break;
      case 2:
        setProgress(60);
        break;
      case 3:
        setProgress(80);
        break;
      case 4:
        setProgress(100);
        break;

      default:
        break;
    }
  }, [step]);

  const nextStep = () => {
    if (step === 1 && !acceptTerms) {
      return;
    } else {
      setStep(step + 1);
    }
  };
  const prevStep = () => {
    setStep(step - 1);
  };

  const retake = () => {
    (window as Window).location = window.location.pathname;
  };
  const shuffle = (array: any[]) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };
  const [assessment, setAssessment] = useState<any>({});
  const [questions, setQuestions] = useState<any>([]);
  const [timeTaken, setTimeTaken] = useState<any>([]);
  const [userLocation, setUserLocation] = useState<string>('');
  const [cancelling, setCancelling] = React.useState(false);
  const [answeredFlags, setAnsweredFlags] = useState(
    Array(questions.length).fill(false)
  );
  const [answeredCount, setAnsweredCount] = useState(0);
  React.useEffect(() => {
    (async () => {
      await axiosPrivate.get(`/assessment/${id}/cumulative`).then((resp) => {
        const suffelData = shuffle(resp?.data?.questions);
        setAssessment(resp?.data);
        setQuestions(suffelData);
      });
    })();
  }, [id]);
  const [selectedAnswers, setSelectedAnswers] = React.useState<any>(
    questions.map(() => [])
  );
  const handleAnswerSelect = (questionIndex: any, answerIndex: any) => {
    const newSelectedAnswers = [...selectedAnswers];

    if (!Array.isArray(newSelectedAnswers[questionIndex])) {
      newSelectedAnswers[questionIndex] = [];
    }

    const answerIdx = newSelectedAnswers[questionIndex].findIndex(
      (answer: any) =>
        answer.answerId === questions[questionIndex].answers[answerIndex]._id
    );

    if (answerIdx > -1) {
      newSelectedAnswers[questionIndex].splice(answerIdx, 1);
    } else {
      if (newSelectedAnswers[questionIndex].length < 1) {
        newSelectedAnswers[questionIndex].push({
          questionId: questions[questionIndex]._id,
          answerId: questions[questionIndex].answers[answerIndex]._id,
          title: questions[questionIndex].answers[answerIndex].title,
        });
      } else {
        toast.info('You cannot select more than one answers.');
        return;
      }
    }

    setSelectedAnswers(newSelectedAnswers);
    const updatedFlags = [...answeredFlags];
    updatedFlags[questionIndex] = true;
    setAnsweredFlags(updatedFlags);
    setAnsweredCount((prevCount) => prevCount + 1);
  };

  const handleTimeReamain = (reaminTime: any) => {
    setTimeTaken(reaminTime);
    if(reaminTime == '0h 00m 00s') {
      setCancelling(true);
      setTimeout(() => {
        window.location.href = '/assessments';
      }, 3000);
    }
  }
  const [loading, setLoading] = useState(false);
  const handleSubmitAssessment = async () => {
    setLoading(true);
    // if (step === 2 && answeredCount !== questions.length) {
    //   toast.error('Please answer all questions before submitting.');
    //   setLoading(false);
    //   return;
    // }
    await axiosPrivate
      .post(
        `/assessment/${id}/results?location=${userLocation}&timeTaken=${timeTaken}`,
        selectedAnswers
      )
      .then((res) => {
        setLoading(false);
        // nextStep();
        // setLoading(false);
        (
          window as Window
        ).location = `/assessments/result?resultId=${res.data._id}&retakeUrl=${window.location.pathname}`;
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
        toast.error(
          err?.response?.data?.message ||
            'Oops! There was an error submitting your assessment!'
        );
      });
  };

  const [acceptTerms, setAcceptTerms] = useState(false);
  const videoRef = React.useRef<any>(null);

  // Function to enable full-screen mode
  const enableFullScreen = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if ((document as any).documentElement.mozRequestFullScreen) {
      (document as any).documentElement.mozRequestFullScreen();
    } else if ((document as any).documentElement.webkitRequestFullscreen) {
      (document as any).documentElement.webkitRequestFullscreen();
    } else if ((document as any).documentElement.msRequestFullscreen) {
      (document as any).documentElement.msRequestFullscreen();
    }
  };

  const disableKeyShortcuts = (event: any) => {
    if (
      event.keyCode === 122 ||
      (event.ctrlKey && event.keyCode === 78) ||
      (event.altKey && event.keyCode === 9)
    ) {
      event.preventDefault();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', disableKeyShortcuts);

    window.onbeforeunload = () =>
      alert('Are you sure you want to leave the test?');
    return () => {
      window.removeEventListener('keydown', disableKeyShortcuts);
      window.onbeforeunload = null;
    };
  }, []);

  const handleFullScreenChange = () => {
    if (
      !(
        document.fullscreenElement ||
        (document as any).mozFullScreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).msFullscreenElement
      )
    ) {
      setCancelling(true);
      setTimeout(() => {
        window.location.href = '/assessments';
      }, 3000);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', disableKeyShortcuts);
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    return () => {
      window.removeEventListener('keydown', disableKeyShortcuts);
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'msfullscreenchange',
        handleFullScreenChange
      );
      window.onbeforeunload = null;
    };
  }, []);

  React.useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.onloadedmetadata = () => {
        videoElement.play();
      };
    }
  }, []);

  const getAddressFromCoordinates = async (latitude: any, longitude: any) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      if (!response.ok) throw new Error('Failed to fetch address');

      const data = await response.json();
      console.log(data, 'data from location api');
      setUserLocation(data.display_name);
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const getUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Position', position);
          const { latitude, longitude } = position.coords;
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  return (
    <>
      {cancelling && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-400 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Cancelling your assessment...
          </h2>
          <p className="w-1/3 text-center text-white">
            This is due to violating the terms.
          </p>
        </div>
      )}
      <video
        ref={videoRef}
        className="h-[150px] w-[200px] fixed bottom-0 right-0"
      />
      {(() => {
        switch (step) {
          case 0:
            return (
              <MainLayout
                headerProgress={progress}
                headerContent={
                  <StepOneHeader
                    nextStep={nextStep}
                    assessmentInfo={assessment}
                  />
                }
              >
                <StepOneContent assessmentInfo={assessment} />
              </MainLayout>
            );
          case 1:
            return (
              <MainLayout
                headerProgress={progress}
                headerContent={
                  <StepOneHeader
                    nextStep={() => {
                      nextStep();
                      enableFullScreen();
                    }}
                    acceptTerms={acceptTerms}
                    step={step}
                    assessmentInfo={assessment}
                  />
                }
              >
                <StepTwoContent
                  prevStep={prevStep}
                  getUserLocation={getUserLocation}
                  setAcceptTerms={setAcceptTerms}
                  enableFullScreen={enableFullScreen}
                />
              </MainLayout>
            );
          case 2:
            return (
              <MainLayout
                headerProgress={progress}
                headerContent={<StepFourHeader assessmentInfo={assessment}  handleTimeReamain={handleTimeReamain}/>}
              >
                <StepThreeContent
                  prevStep={prevStep}
                  nextStep={handleSubmitAssessment}
                  questions={questions}
                  selectedAnswers={selectedAnswers}
                  handleAnswerSelect={handleAnswerSelect}
                  loading={loading}
                  unansweredQuestionIds={unansweredQuestionIds}
                />
              </MainLayout>
            );
          // case 3:
          //   return (
          //     <MainLayout
          //       headerProgress={progress}
          //       headerContent={<StepFourHeader />}
          //     >
          //       <StepFourContent prevStep={prevStep} nextStep={nextStep} />
          //     </MainLayout>
          //   );
          // case 4:
          //   return (
          //     <MainLayout
          //       headerProgress={progress}
          //       headerContent={<StepFourHeader />}
          //     >
          //       <StepFiveContent prevStep={prevStep} nextStep={nextStep} />
          //     </MainLayout>
          //   );
          case 3:
            return (
              <MainLayout
                headerProgress={100}
                headerContent={<StepSixHeader retake={retake} />}
              >
                <StepSixContent id={resultId || ''} />
              </MainLayout>
            );

          default:
            break;
        }
      })()}
    </>
  );
};

export default AssessmentsOverview;
