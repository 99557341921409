import React, { useState, useEffect } from 'react';
import { axiosPublic } from '../../api/axios';
import { Chart as ChartJS, registerables } from 'chart.js';
import newImage from '../../assets/new-image.png';
ChartJS.register(...registerables);

// Define the interface for the data
interface CertificateData {
    assessmentTitle?: string;
    name?: string;
}

interface MainContentProps {
    id: string;
} 

const MainContent: React.FC<MainContentProps> = ({ id }) => {
    const [data, setData] = useState<CertificateData>({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axiosPublic.get(`/certificate-detail/${id}`);
                setData(res.data);
                
            } catch (error) {
                console.error('Error fetching certificate details:', error);
            }
        };
        fetchData();
    }, [id]);

    return (
        <div style={{ width: '100%', height: '100%' }} className='table-main-wrapper'>
            <table
                cellPadding="0"
                cellSpacing="0"
                style={{ width: "100%", maxWidth: "100%", margin: "auto" }}
            >
                <tbody>
                    <tr>
                        <td style={{ padding: "0", position: "relative" }}>
                            <img
                                src={newImage}
                                alt="Certificate background"
                                style={{ display: "block", width: "100%", height: "100vh" }}
                            />
                            <p className="name-text" id="">
                                <span className="font-custom-size" id="font-custom-size">
                                    {data.name}
                                </span>
                                <span className="name-text-small" id="name-text-small">
                                    For successfully completing the
                                </span>
                                <span className="font-custom-size" id="font-custom-size2">
                                    {data.assessmentTitle}
                                </span>
                                <span className="name-text-small green-text" id="name-text-small1">
                                    Profiling Platform.
                                </span>
                                <span className="name-text-small green-text" id="name-text-small2">
                                    Skill Assessment through our Enterprise Skills
                                </span>
                                <span className="number-text" id="number-text">
                                    <strong>Certificate Number :</strong> {id}
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* <table
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}
            >
                <tbody>
                    <tr>
                        <td style={{ padding: 0, position: 'relative' }}>
                            <img
                                src="https://third-bracket.s3.eu-north-1.amazonaws.com/0.029283381473754977-thmb-1710174795122"
                                alt="Certificate background"
                                style={{ display: 'block', width: '100%', height: 'auto' }}
                            />
                            <p className="name-text">
                                Name: {data.name} <span> Skill Profile For: {data.assessmentTitle}</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table> */}
        </div>
    );
};

export default MainContent;
